import { observer } from 'mobx-react-lite';
import moment from 'moment';
import * as React from 'react';
import { AiOutlineMenu } from "react-icons/ai";
import Moment from 'react-moment';
import { Link, NavLink } from 'react-router-dom';
import { Container, Dropdown, Header, Icon, Image, Label, Menu, Visibility } from 'semantic-ui-react';
import AvatarImage from '../../common/image/AvatarImage';
import { useStore } from '../../stores/store';


const NavBar = () => {
    const { authStore: { logout}, commonStore: { toggleSidebar, isCollapseSideBar }, userStore: { user } } = useStore();
    const [showProfileMenu, toggleshowProfileMenu] = React.useState(false);

    const handleClickShowProfileMenu = () => {
        toggleshowProfileMenu(!showProfileMenu);
    };

    let dateToday = moment();

    return (
        <Menu fixed='top' borderless style={{ boxShadow: 'none' }}>
            <Container fluid>
                <Menu.Item onClick={toggleSidebar} style={{ width: '80px', display: 'flex', justifyContent: 'center' }}>
                    <AiOutlineMenu fontSize='1.7em' />
                </Menu.Item>
                <Menu.Item as={Link} to="/" exact header style={{ paddingLeft: '.5em', display: isCollapseSideBar ? 'none': 'block' }}>
                    <Image src='/assets/kasina-logo.svg' alt='logo' size='small' style={{ width: '135px' }} />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Header as='h4' style={{ color: '#8d9eb4', fontWeight: '400' }}>
                            <Moment format='dddd, MMMM DD, YYYY' >
                                {dateToday}
                            </Moment>
                        </Header>
                    </Menu.Item>
                    <Menu.Item style={{ padding: '.9em', marginRight: '1em', cursor: 'pointer' }} onClick={handleClickShowProfileMenu}>
                        <AvatarImage userId={user?.id!} hasPhoto={user?.hasProfilePicture!} style={{ width: '40px', height: '40px' }} avatar circular />
                        <Dropdown open={showProfileMenu} icon='chevron down' className='small' pointing='top right'>
                            <Dropdown.Menu>
                                {/*<Dropdown.Item text='Change Password' icon='user' />*/}
                                <Dropdown.Item onClick={logout} text='Logout' icon='power' />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                </Menu.Menu>
            </Container>
        </Menu>
    );
}

export default observer(NavBar);