import * as React from 'react';
import { FiSettings } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import { GoFileSubmodule } from "react-icons/go";
import { GrDatabase } from "react-icons/gr";
import { RiUserSettingsLine, RiProfileLine } from "react-icons/ri";
import * as Permissions from '../../constants/permissions';

export const SidebarData = [
    { text: "Application & Modules", path: "/applications", icon: <GoFileSubmodule style={{ fontSize: '1.7rem' }} />, permission: Permissions.ApplicationAndModules },
    { text: "Role", path: "/roles", icon: <RiUserSettingsLine style={{ fontSize: '1.7rem' }} />, permission: Permissions.Roles },
    { text: "User", path: "/users", icon: <FaUsers style={{ fontSize: '1.7rem' }} />, permission: Permissions.UserRoles },
    //{ text: "Data Logger", path: "/datalogger", icon: <GrDatabase style={{ fontSize: '1.7rem' }} /> },
    //{ text: "Company Profile", path: "/profiles", icon: <RiProfileLine style={{ fontSize: '1.7rem' }} /> },
    //{ text: "Settings", path: "/settings", icon: <FiSettings style={{ fontSize: '1.7rem' }} /> },
];

