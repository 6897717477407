import styled, { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
    borderRadius: '0',
    palette: {
        common: {
            black: '#222831',
            white: '#ffffff'
        },
        primary: {
            main: '#1592ff',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#709fb0',
            contrastText: '#ffffff'
        }
    }
}