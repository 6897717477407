import { Form, Formik } from 'formik';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import * as Yup from 'yup';
import TextInput from '../../app/common/form/TextInput';
import { useStore } from '../../app/stores/store';

const ForgotPasswordForm = () => {
    const { userStore: { isLoggedIn, forgotPassword } } = useStore();

    if (isLoggedIn) return <Redirect to='/application' />;

    return (
        <Formik
            initialValues={{ username: '' }}
            onSubmit={(values, { setErrors }) => forgotPassword(values)}
            validationSchema={Yup.object({
                username: Yup.string().required('Username field is required.').email('Invalid Username.')
            })}
        >
            {
                ({ handleSubmit, isSubmitting, errors }) => (

                    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                        <Grid.Column style={{
                            width: '436px',
                            position: 'relative',
                            top: '-20px',
                            padding: '54px 32px',
                            boxShadow: '0px 3px 6px rgb(21 146 255 / 16%)',
                            borderRadius: '16px',
                            backgroundColor: '#ffffff'
                        }}>
                            <div style={{ textAlign: 'center', marginBottom: '32px' }}>
                                <Image src={`assets/kasina-logo.svg`} style={{ width: '135px', marginBottom: '12px' }} centered />
                                <Header as='h6' style={{ letterSpacing: '5.6px', fontSize: '8px', marginTop: 0, marginLeft: 20 }}>ADMIN PORTAL</Header>
                            </div>
                            <Header as='h3' style={{ marginBottom: '32px', textAlign: 'left' }}>Forgot Password</Header>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <TextInput name='username' placeholder='Username ' label='Username ' type='text' />
                                <Grid columns='equal' style={{ marginTop: '30px', alignItems: 'center' }}>
                                    <Grid.Column style={{textAlign: 'left'}}>
                                        <i className='angle left icon'></i>
                                        <Link to={`/login`}>
                                            Back
                                        </Link >
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button loading={isSubmitting} color='blue' style={{ backgroundColor: '#1592ff', borderRadius: '8px' }} fluid content='Reset Password' type='submit' size='medium' />
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </Grid.Column>
                    </Grid>
                )
            }
        </Formik>
    );
}

export default ForgotPasswordForm;