import { makeAutoObservable, reaction, runInAction } from 'mobx';
import _default, { array } from 'yup/lib/locale';
import { history } from "../..";
import agent from '../api/agents';
import CookieHelper from '../helpers/cookieHelper';
import { UserFormValues } from "../models/user";
import { store } from "./store";
import { Auth } from "../models/auth";

const _cookieHelper = new CookieHelper();

export default class AuthStore {
    token: string | null = _cookieHelper.get('jsonWebToken') || null;
    refreshToken: string | null = _cookieHelper.get('refreshToken') || null;
    auth: Auth | null = null;
    refreshTokenTimeout: any;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            token => {
                if (token) {
                    _cookieHelper.set('jsonWebToken', token);
                }
                else {
                    _cookieHelper.delete('jsonWebToken');
                }
            }
        );

        reaction(
            () => this.refreshToken,
            token => {
                if (token) {
                    _cookieHelper.set('refreshToken', token);
                }
                else {
                    _cookieHelper.delete('refreshToken');
                }
            }
        );
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    setRefreshToken = (refreshToken: string | null) => {
        this.refreshToken = refreshToken;
    }

    login = async (creds: UserFormValues) => {
        try {
            const accessToken = await agent.Account.login(creds);
            this.setToken(accessToken.jsonWebToken!);
            this.setRefreshToken(accessToken.refreshToken!);
            const user = await agent.Account.current();
            this.startRefreshTokenTimer();
            runInAction(() => {
                store.userStore.user = user;
                store.userStore.setDisplayName(user);
                store.commonStore.isCollapseSideBar = false;
                history.push('/applications');
            });
        } catch (e) {
            console.error(e);
        }
    }

    hasPermission = (permission: string | undefined) => {
        if (!this.auth) this.setUserAuth();

        if (permission) {
            let userPermission = this.auth?.permissions.find(p => p == permission);
            return userPermission != undefined;
        }

        return false;
    }

    logout = () => {
        this.setToken(null);
        this.setRefreshToken(null);
        _cookieHelper.delete('jsonWebToken');
        _cookieHelper.delete('refreshToken');
        store.userStore.user = null;
        this.auth = null;
        history.push('/');
    }

    setUserAuth = () => {
        if (this.token) {
            var base64Url = this.token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            const { permissions } = JSON.parse(jsonPayload);

            if (Array.isArray(permissions)) {
                this.auth = {
                    permissions: permissions
                }
            }
        }

        return this.auth;
    };

    refreshTheToken = async () => {
        try {
            const accessToken = await agent.Account.refreshToken({
                jsonWebToken: this.token!,
                refreshToken: this.refreshToken!
            });

            runInAction(() => {
                this.setToken(accessToken.jsonWebToken!);
                this.setRefreshToken(accessToken.refreshToken!);
                this.startRefreshTokenTimer();
            })
        } catch (error) {
            console.log(error);
        }
    }

    startRefreshTokenTimer() {
        if (this.token) {
            const jwtToken = JSON.parse(atob(this.token!?.split('.')[1]));
            const expires = new Date(jwtToken.exp * 1000);
            const timeout = expires.getTime() - Date.now() - (30 * 1000);
            this.refreshTokenTimeout = setTimeout(this.refreshTheToken, timeout);
        }
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}