import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import TextInput from "../../app/common/form/TextInput";
import { Formik, Form } from 'formik';
import { Button, Dimmer, Dropdown, Grid, Loader, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { CompanyProfile } from "../../app/models/companyProfile";
import StyledHeader from "../../app/common/styled-element/StyledHeader";
import styled from "styled-components";

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const CompanyProfileForm = () => {
    const { companyProfileStore, lookupStore } = useStore();
    const { isLoading, loadCompanyProfile, updateCompanyProfile } = companyProfileStore;
    const { isLoading: lookupLoading, getAllSocialMedia } = lookupStore;
    const history = useHistory();
    const [options, setOptions] = useState([{ key: '', text: '', value: '', className: '' }]);
    const [defaultOption, setDefaultOption] = useState('');

    const [companyProfile, setCompanyProfile] = useState({
        id: '',
        companyName: '',
        address1: '',
        address2: '',
        city: '',
        province: '',
        country: '',
        zipCode: '',
        phoneNumber: '',
        mobileNumber: '',
        website: '',
        socialMediaId: ''
    });

    useEffect(() => {
        getAllSocialMedia().then(socialmedia => {
            const option = { key: '', text: '', value: '', className: '' };
            let tempOptions = [option];
            tempOptions = [];

            socialmedia?.map(media => {
                tempOptions.push({ key: media.id, text: media.name, value: media.id, className: 'social-media-item' });
            });

            setOptions(tempOptions);

            loadCompanyProfile().then(profile => {
                if (profile) {
                    setCompanyProfile(profile!);
                    setDefaultOption(profile!.socialMediaId);
                }
            });
        });
    }, [loadCompanyProfile, getAllSocialMedia, setOptions]);

    const handledropDownChange = (event: any, data: any) => {
        const { value } = data;
        setDefaultOption(value)
    }

    const handleSubmitForm = (values: CompanyProfile) => {
        values.socialMediaId = defaultOption;
        updateCompanyProfile(values);
    }

    return (
        <StyledContainer>
            <Formik
                enableReinitialize
                initialValues={companyProfile}
                onSubmit={handleSubmitForm}
            >
                {
                    ({ handleSubmit, isSubmitting, errors }) => (
                        <>
                            <StyledHeader content="Company Profile" />
                            <Segment clearing style={{ borderRadius: 16, paddingTop: '1.5em', paddingY: '1em' }}>
                                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                    <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='Company Name' name='companyName' label='Company Name' />
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='Address 1' name='address1' label='Address 1' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput placeholder='Address 2' name='address2' label='Address 2' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='City' name='city' label='City' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput placeholder='Province / State' name='province' label='Province / State' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='Country' name='country' label='Country' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput placeholder='Zip Code' name='zipCode' label='Zip Code' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='Phone Number' name='phoneNumber' label='Phone Number' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput placeholder='Mobile Number' name='mobileNumber' label='Mobile Number' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='Website' name='website' label='Website' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <label style={{ fontSize: 13, fontWeight: 700 }}>Social Media</label>
                                                <Dropdown
                                                    id="socialMediaDropdown"
                                                    style={{ marginTop: 5 }}
                                                    placeholder='Social Media'
                                                    fluid
                                                    search
                                                    selection
                                                    options={options}
                                                    value={defaultOption}
                                                    onChange={handledropDownChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Button style={{ marginTop: 15 }} floated='right' content='Cancel' onClick={() => history.push('/roles')} size='large' />
                                    <Button style={{ marginTop: 15 }} loading={isLoading} floated='right' content='Save' primary type='submit' size='large' />
                                </Form>
                            </Segment>
                        </>
                    )
                }
            </Formik>
            <Dimmer active={isLoading && lookupLoading} inverted>
                <Loader inverted>Loading Company Profile...</Loader>
            </Dimmer>
        </StyledContainer>
    );
};

export default observer(CompanyProfileForm);