import { stat } from 'fs';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { bool } from 'yup';
import agent from '../api/agents';
import { ApplicationModule, Application, SelectApplication } from '../models/application';

export default class ApplicationStore {
    applications: Application[] = [];
    loadingInitial = false;
    isLoading = false;
    selectApplication: SelectApplication[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    get applicationModule() {
        let appModule: ApplicationModule[] = [];

        if (this.applications.length === 0) this.loadApplications();

        this.applications.forEach(app => {
            appModule.push({
                application: app.name
            });

            app.modules.forEach(m => {
                appModule.push({
                    module: m.name
                });
            });
        });

        return appModule;
    }

    get getApplications() {
        if (this.applications.length == 0)
            this.loadApplications();

        return this.applications;
    }


    loadApplications = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            const applications = await agent.Applications.list();
            runInAction(() => {
                this.applications = applications;
                this.getSelectApplications();
            });
            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }



    setSelectedApplication = (applicationId: string, state: boolean) => {
        this.selectApplication = this.selectApplication.map(a => {
            if (a.id === applicationId) {
                return {
                    ...a,
                    isChecked: state,
                    modules: a.modules.map(m => { return { ...m, isChecked: state } })
                }
            }
            else {
                return a;
            }
        });
    }

    setSelectedModule = (moduleId: string, state: boolean) => {
        const application = this.selectApplication.find(a => a.modules.find(m => m.id === moduleId));
        this.selectApplication = this.selectApplication.map(a => {
            let newModuleState = a.modules.map(m => {
                if (!state && m.id === moduleId && m.isLandingPage) {
                    if (a.modules.some(m => m.id !== moduleId && m.isChecked)) {
                        toast.warning('Landing page is required.');
                        return m;
                    }
                }

                if (state && m.isLandingPage && application?.id === a.id)
                    return { ...m, isChecked: state }

                if (m.id === moduleId) {
                    return { ...m, isChecked: state }
                }
                else
                    return m;
            });

            let checkedLength = newModuleState.filter(m => m.isChecked === true).length;
            let applicationState = a.modules.length === checkedLength;

            return {
                ...a,
                isChecked: applicationState,
                modules: newModuleState
            }
        });
    }

    getSelectedModule = () => {
        let modules = Array<string>();

        this.selectApplication.forEach(a => {
            a.modules.forEach(m => {
                if (m.isChecked)
                    modules.push(m.id);
            })
        })

        return modules;
    }

    private getSelectApplications = () => {
        if (this.applications.length == 0)
            this.loadApplications();

        this.selectApplication = [];

        this.applications.forEach(app => {
            let selectApp = {
                id: app.id,
                name: app.name,
                isChecked: false,
                modules: app.modules.map(m => {
                    return {
                        id: m.id,
                        name: m.name,
                        isChecked: false,
                        isLandingPage: m.isLandingPage
                    }
                })
            };

            this.selectApplication.push(selectApp);
        });

        return this.selectApplication;
    }
}