import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, List, Popup, } from "semantic-ui-react";
import { Role } from "../../app/models/role";
import { useStore } from '../../app/stores/store';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import PaginatedTable from "../../app/common/table/PaginatedTable";
import StyledHeader from "../../app/common/styled-element/StyledHeader"

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const RoleList = () => {
    const { roleStore } = useStore();
    const { total, filteredRoles, setPageNumber, pageNumber, isLoading, setSearchQuery, filterRoles, deleteRole, filterValues } = roleStore;
    const swal = withReactContent(Swal)

    const history = useHistory();

    const routeChange = (path: string) => {
        history.push(path);
    }

    const onRoleDelete = (rowData: Role) => {
        swal.fire({
            title: 'Delete Confirmation',
            text: "Are you sure you want to delete this role?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRole(rowData.id);
            }
        });


    }

    useEffect(() => {
        filterValues.pageNumber = 1;
        filterValues.searchKeyword = '';
        filterRoles();
    }, [filterRoles]);

    return (
        <>
            <Button
                primary floated='right' style={{ marginRight: 28 }}
                onClick={() => routeChange('roles/create')}>
                Add a New Role
            </Button>
            <StyledContainer>
                <StyledHeader content="Roles" />
                <PaginatedTable<Role>
                    columns={[
                        { title: 'Role', field: 'isDefault', hidden: true },
                        { title: 'Role', field: 'id', hidden: true },
                        { title: 'Role', field: 'name' },
                        { title: 'Description ', field: 'description' },
                        {
                            title: 'Users',
                            field: 'users',
                            render: (data) => {
                                if (data.users) {
                                    let users = data.users.map(u => u.displayName).slice(0, 2).join(', ');

                                    if (data.users.length > 2) {
                                        users = users + "...";
                                        return (
                                            <Popup
                                                trigger={<label>{users}</label>}
                                                content={
                                                    <div style={{ maxHeight: 500, overflowY: "auto"}}>
                                                        <List>
                                                            {data.users.map(u =>
                                                                <List.Item>{u.displayName}</List.Item>
                                                            )}
                                                        </List>
                                                    </div>
                                                }
                                                hoverable
                                                position="left center"
                                            />
                                        )
                                    };

                                    return users;
                                }

                                return "";
                            }
                        },

                    ]}
                    actions={[
                        rowData => ({
                            icon: 'edit',
                            tooltip: 'Edit Role',
                            onClick: (event) => {
                                routeChange(`roles/edit/${rowData.id}`)
                            }
                        }),
                        rowData => ({
                            icon: 'delete',
                            tooltip: 'Delete Role',
                            onClick: e => {
                                onRoleDelete(rowData)
                            },
                            hidden: rowData.isDefault
                        })
                    ]}
                    total={total}
                    pageSize={10}
                    pageNumber={pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={p => {
                        setPageNumber(p);
                    }}
                    onSearchChange={query => {
                        setSearchQuery(query);

                    }}
                    data={filteredRoles}
                    isLoading={isLoading}
                    toolbar={true}
                    search={true}
                />
            </StyledContainer>
        </>
    )
}

export default observer(RoleList);