import { observer } from "mobx-react-lite";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { Container } from "semantic-ui-react";
import SidebarMenu from "../common/sidebar-menu/SideBarMenu";
import { useStore } from "../stores/store";
import NavBar from "../common/navbar/NavBar";
import styled from 'styled-components';
import Unauthorize from "../../features/errors/Unauthorize";

//#region "Styling"
const StyledAppWrapper = styled.div<{ isCollapse: boolean }>`
        height: '100%';
        overflow-y: auto;
        position: fixed;
        top: 60px;
        left: 240px;
        bottom: 0;
        right: 0;
        padding: 2rem 1rem;
        background-color: #f3f9ff !important;
        border-bottom-right-radius: 20px;
        transition: all 0.5s ease;

        & .item {
            background: #fff;
            margin-bottom: 10px;
            padding: 15px;
            font-size: 14px;
            line-height: 22px;
        }

          ${p => (p.isCollapse && `
                left: 90px;
                top: 60px;
            `)};
`;

//#endregion "Styling"


interface Props extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    permission?: string;
}

function PrivateRoute({ component: Component, exact, permission, ...rest }: Props) {
    const { userStore: { isLoggedIn }, commonStore: { isCollapseSideBar }, authStore: { hasPermission } } = useStore();

    return (
        <Route
            {...rest}
            exact={exact ? exact : false}
            render={(props) => hasPermission(permission) && isLoggedIn ? (
                <>
                    <NavBar />
                    <SidebarMenu />
                    <StyledAppWrapper isCollapse={isCollapseSideBar}>
                        <Container
                            style={{ marginTop: '1em', }}
                            fluid>
                            <Component {...props} />
                        </Container>
                    </StyledAppWrapper>
                </>
            ) : isLoggedIn ? <Unauthorize /> : <Redirect to='/login' />}
        />
    )
}

export default observer(PrivateRoute);