import styled from 'styled-components';
import StyledHeader from '../../app/common/styled-element/StyledHeader';
import ApplicationModuleList from './ApplicationModuleList'

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const ApplicationModuleDashboard = () => {
    return (
        <StyledContainer>
            <StyledHeader content="Applications & Modules" />
            <ApplicationModuleList />
        </StyledContainer>
    );
}

export default ApplicationModuleDashboard;