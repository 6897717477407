import React, { ChangeEvent, useState } from 'react';
import { Icon, Input } from 'semantic-ui-react';
import styled from 'styled-components';

const ContainerStyled = styled.div`
    width:100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

`;

const SearchStyled = styled(Input)`
    flex-grow:1;
    input {
             border:none !important;
        }
`;

const StyleIcon = styled(Icon)`
    font-size: 1.3em !important;
    color: #8d9eb4 !important;
`;


const SearchToolbar = (props: any) => {
    const [query, setQuery] = useState(props.searchText);

    const handleClear = () => {
        props.dataManager.searchText = '';
        setQuery('');
        props.onSearchChanged('');
    }

    const handleChange = (e: ChangeEvent) => {
        let element = e.target as HTMLInputElement;
        const value = element.value ?? '';
        setQuery(value);
    }

    return (
        <ContainerStyled>
            <StyleIcon name='search' />
            <SearchStyled
                value={query}
                onChange={handleChange}
                onKeyPress={(ev: React.KeyboardEvent) => {
                    if (ev.key === 'Enter') {
                        let element = ev.target as HTMLInputElement;
                        const value = element.value ?? '';
                        props.dataManager.searchText = value;
                        props.onSearchChanged(value ? value : '');
                        ev.preventDefault();
                    }
                }}

                icon={<Icon name='close'  link onClick={handleClear} style={{ display: query.length ? 'inline-block' : 'none' }} />}
                placeholder='Search name...'
            />
        </ContainerStyled>

    );
}

export default SearchToolbar;