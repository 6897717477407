import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './app/layout/App';

import 'react-toastify/dist/ReactToastify.min.css';
import 'semantic-ui-css/semantic.min.css';
import './app/layout/styles.css';


import { createBrowserHistory } from 'history';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import { store, StoreContext } from './app/stores/store';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from './app/styles/theme';

export const history = createBrowserHistory();

ReactDOM.render(
    <StoreContext.Provider value={store}>
        <Router history={history}>
            <ThemeProvider theme={defaultTheme}>
                <App />
            </ThemeProvider>
        </Router>
    </StoreContext.Provider>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
