import { makeAutoObservable, reaction } from 'mobx';
import CookieHelper from '../helpers/cookieHelper';

const _cookieHelper = new CookieHelper();

export default class CommonStore {
    appLoaded = false;
    isCollapseSideBar = false;
    subNavId: number | undefined = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }

    toggleSidebar = () => {
        this.isCollapseSideBar = !this.isCollapseSideBar;
    }

    setSubNav = (id?: number) => {
        if (id == this.subNavId || id === 0)
            this.subNavId = 0;
        else
            this.subNavId = id;
    };
}