import { runInAction } from "mobx";
import agent from "../api/agents";
import { CompanyProfile } from "../models/companyProfile";
import { toast } from 'react-toastify';

export default class CompanyProfileStore {
    isLoading = false;
    companyProfile: CompanyProfile | undefined = undefined;

    updateCompanyProfile = async (companyProfile: CompanyProfile) => {
        this.isLoading = true;

        try {
            await agent.CompanyProfiles.update(companyProfile);
            runInAction(() => {
                this.isLoading = false;
                toast.success("Company Profile is successfully updated.");
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };

    loadCompanyProfile = async () => {
        this.isLoading = true;

        try {
            const companyProfile = await agent.CompanyProfiles.details();
            this.isLoading = false;

            this.companyProfile = companyProfile;
            return companyProfile;
        } catch (error) {
            console.log(error);
            this.isLoading = false;
            return;

        }
    }
}