import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { useStore } from '../../app/stores/store';
import RoleList from './RoleList';


const RoleDashBoard = () => {
    return (
        <RoleList />
    );
}

export default observer(RoleDashBoard);