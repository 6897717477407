import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';

export default function NotFound() {
    return (
        <Grid centered style={{ marginTop: '3em' }}>
            <Grid.Row centered>
                <Grid.Column width={10}>
                    <Segment placeholder>
                        <Header icon>
                            <Icon name='search' />
                            Oops - we've looked everywhere and could not find this.
                        </Header>
                        <Segment.Inline>
                            <Button as={Link} to='/login' primary>
                                Return to login page
                            </Button>
                        </Segment.Inline>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}