import { makeAutoObservable } from "mobx";
import agent from "../api/agents";
import { SocialMedia } from "../models/socialMedia";

export default class LookupStore {
    isLoading = false;
    socialMedia: SocialMedia[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    getAllSocialMedia = async () => {
        this.setLoading(true);

        if (this.socialMedia.length > 0) {
            this.setLoading(false);
            return this.socialMedia;
        }

        try {
            const socialMedia = await agent.Lookups.socialMedia();
            this.setLoading(false);
            this.socialMedia = socialMedia;
            return socialMedia;
        } catch (error) {
            console.error(error);
            this.setLoading(false);
        }
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }
}