import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';

export default function Anauthorize() {
    return (
        <Segment style={{ marginLeft: 200, marginRight: 200, height: 500}} placeholder>
            <Header icon>
                <Icon name='times rectangle' />
                <h1 style={{ marginBottom: 20 }}>Access to {window.location.origin.toString()} was denied</h1>
                You don't have authorization to view this page.
            </Header>
            <Segment.Inline>
                <Button as={Link} to='/login' primary>
                    Reload
                </Button>
            </Segment.Inline>
        </Segment>

    );
}