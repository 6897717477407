import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { store } from "../stores/store";
import cookieHelper from "../helpers/cookieHelper";

const axiosService = axios.create();

axiosService.defaults.baseURL = process.env.REACT_APP_API_URL;

axiosService.interceptors.request.use(config => {
    const helper = new cookieHelper();
    let token: string | null = "";

    if (helper.get("jsonWebToken")) 
        token = store.authStore.token;

    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axiosService.interceptors.response.use(async res => {
    return res;
}, (error: AxiosError) => {
    const { status, data, config, headers } = error.response!;
    toast.dismiss();
    switch (status) {
        case 400:
            toast.error(data);
            break;
        case 401:
            if (headers['www-authenticate']) {
                if (headers['www-authenticate'].startsWith('Bearer error="invalid_token"')) {
                    store.authStore.logout();
                    toast.error('Session expired. Please login again');
                }
            } else if (data) {
                store.authStore.logout();
                toast.error(data);
            }
            else {
                toast.error("Unauthorized.");
            }
            break;
        case 404:
            if (data) {
                toast.error(data);
            } else {
                toast.error('Not Found');
            }
            break;
        case 409:
            if (data) {
                toast.error(data);
            } else {
                toast.error('Conflict');
            }
            break;
        case 500:
            toast.error('Server Error');
            break;
    }
    return Promise.reject(error);
});

export default axiosService;