import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from 'material-table';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Tab } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from '../../app/stores/store';


const ApplicationModuleList = () => {
    const { applicationStore } = useStore();
    const { isLoading, loadingInitial, getApplications } = applicationStore;


    if (loadingInitial) return <LoadingComponent content='Loading Applications and Modules...' />

    return (
        <div style={{ maxWidth: '100%' }}>
            <Tab
                style={{ marginTop: 15 }}
                panes={getApplications.map((application) => {
                    return {
                        menuItem: application.name,
                        render: () =>
                            <Tab.Pane key={application.id}>
                                <MaterialTable
                                    columns={[
                                        { title: 'Modules', field: 'name' },
                                        {
                                            title: 'Description', field: 'description', cellStyle: {
                                                width: "60%"
                                            } },
                                    ]}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: 'No records to display',
                                        }
                                    }}
                                    data={application.modules}
                                    isLoading={isLoading}
                                    style={{ padding: '0 20px' }}
                                    options={{
                                        search: false,
                                        showTitle: false,
                                        paging: false,
                                        sorting: false,
                                        draggable: false,
                                        toolbar: false,
                                        headerStyle: {
                                            background: '#d7e3fa',
                                            fontWeight: 'bold',
                                            fontSize: '15px'
                                        },
                                        rowStyle: (rowData, index: number) => ({
                                            backgroundColor: index % 2 === 0 ? "#ffffff " : "#f5f8ff",
                                        }),
                                    }}
                                />
                            </Tab.Pane>
                    }
                })} />

        </div>
    )
}

export default observer(ApplicationModuleList);