import { makeStyles, Paper } from '@material-ui/core';
import MaterialTable, { Action, Column, MaterialTableProps, MTableEditRow, MTableFilterRow, MTablePagination, Query, QueryResult } from 'material-table';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CSSProperties } from 'react';
import { Header } from 'semantic-ui-react';
import SearchToolbar from './SearchToolbar';

const useStyles = makeStyles({
    theme: {
        boxShadow:'none',
        '& [class*="Component-horizontalScrollContainer"]': {
            padding:'0 8px'
        },
        '& .MuiTablePagination-root': {
            borderBottom: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            minHeight: '60px',
        },
        '& .MuiTablePagination-toolbar .MuiButton-label': {
            fontSize: '1rem'
        },
        '& .MuiTablePagination-toolbar .MuiIconButton-label > span': {
            fontSize: '1.6rem'
        }
    }
});

interface Props<T extends object> extends MaterialTableProps<T> {
    columns: Column<T>[];
    total: number;
    data: T[] | ((q: Query<T>) => Promise<QueryResult<T>>);
    onChangePage: (page: number) => void;
    pageSize: number;
    pageNumber: number;
    isLoading?: boolean;
    actions?: ((rowData: T) => Action<object>)[];
    onSearchChange?: ((searchText: string) => void) | undefined;
    search?: boolean;
    toolbar?: boolean;
    searchPlaceholder?: string;
    style?: CSSProperties;
    sorting?: boolean;
    draggable?: boolean;
}

function PaginatedTable<T extends object>(props: Props<T>) {
    const classes = useStyles();

    const addCellStyleInColumns = () => {
        return props.columns.map((c, i) => {
            if (i == 0) {
                return {
                    ...c,
                    cellStyle: { borderBottom: 'none', paddingLeft: '30px' },
                    headerStyle: { paddingLeft: '30px' }
                }

            }
            else if (!props.actions && i + 1 == props.columns.length) {
                return {
                    ...c,
                    cellStyle: { borderBottom: 'none', paddingRight: '30px' },
                    headerStyle: { paddingRight: '30px' }
                }
            }
            else {
                return {
                    ...c,
                    cellStyle: { borderBottom: 'none' },
                }
            }
        });
    }

    return (
        <MaterialTable<T>
            columns={addCellStyleInColumns()}
            actions={props.actions}
            page={props.pageNumber > 0 ? props.pageNumber - 1 : props.pageNumber}
            totalCount={props.total ?? 0}
            localization={{
                body: {
                    emptyDataSourceMessage: <Header as='h4' style={{ textAlign: 'center', fontWeight: 600, padding: '1em' }}>No Records</Header>,
                },
                toolbar: { searchPlaceholder: props.searchPlaceholder ?? 'Search name...' }
            }}
            onChangePage={(p) => props.onChangePage(p + 1)}
            onSearchChange={props.search ? props.onSearchChange : undefined}
            components={{
                Toolbar: (props) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "left",
                            width: "100%",
                            padding: '16px 16px',
                            borderBottom: '2px solid #d7e8f9'
                        }}
                    >
                        <SearchToolbar {...props} />
                    </div>
                ),
                Container: (props) => <Paper className={classes.theme} {...props} />

            }}
            data={props.data}
            isLoading={props.isLoading ?? false}
            style={{ borderRadius: '16px', ...props.style }}
            options={{
                paginationType: 'stepped',
                actionsColumnIndex: props.actions ? - 1 : undefined,
                showTitle: false,
                pageSize: props.pageSize,
                pageSizeOptions: [props.pageSize],
                loadingType: 'linear',
                debounceInterval: 500,
                tableLayout: 'auto',
                headerStyle: {
                    fontWeight: 600, fontSize: '1.1em',
                    borderBottom: '1px solid rgb(224 224 224)'
                },
                searchFieldAlignment: 'left',
                searchFieldVariant: 'standard',
                searchFieldStyle: { padding: '10px', width: '100%' },
                toolbarButtonAlignment: undefined,
                search: props.search || false,
                toolbar: props.toolbar || false,
                maxBodyHeight: 500,
                emptyRowsWhenPaging: false,
                actionsCellStyle: {
                    display: 'flex',
                    justifyContent: 'center',
                    padding: `${props.actions?.length ? 10 / props.actions.length : 0}px 20px`,
                    width: '100%',
                    marginBottom: '-1px',
                    borderBottom: 'none',
                },
                rowStyle: (_data: any, index: number, _level: number) => {
                    return index % 2 ? { backgroundColor: '#f3f9ff', padding: '0 20px' } : { padding: '0 20px' };
                },
                filterCellStyle: { border: 'none' },
                sorting: props.sorting,
                draggable:props.draggable,
            }}
        />
    );
}

export default observer(PaginatedTable);