import { useField } from 'formik';
import * as React from 'react';
import { Form, Label } from 'semantic-ui-react';

interface Props {
    placeholder: string;
    name: string;
    label?: string;
    type?: string;
    disabled?: boolean;
    labelStyle?: React.CSSProperties;
}

export default function TextInput({ labelStyle, label, ...rest }: Props) {
    const [field, meta] = useField(rest.name);
    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ textAlign: 'left' }}>
            <label style={labelStyle}>
                {label}
            </label>
            <input {...field} {...rest} />
            {meta.touched && meta.error ? (<Label style={{ marginTop:'10px'}}  basic color='red'>{meta.error}</Label>) : null}
        </Form.Field>
    );
}