import { observer } from "mobx-react-lite";
import { ChangeEvent, useState } from "react";
import { Button, Checkbox, CheckboxProps, Header, Label, Segment, Tab, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import LoadingComponet from "../../../app/layout/LoadingComponent"
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInput from "../../../app/common/form/TextInput";
import TextArea from "../../../app/common/form/TextArea";
import { Role } from "../../../app/models/role";
import StyledHeader from "../../../app/common/styled-element/StyledHeader";
import styled from "styled-components";
import { toast } from 'react-toastify';

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const RoleForm = () => {
    const { applicationStore, roleStore } = useStore();
    const { loadApplications, loadingInitial, selectApplication, setSelectedApplication, setSelectedModule, getSelectedModule } = applicationStore;
    const { createRole, loadRole, isLoading: roleLoading, updateRole } = roleStore;
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [role, setRole] = useState({
        id: '',
        name: '',
        description: '',
        isDefault: false,
        moduleIds: Array<string>()
    });

    const [title, setTitle] = useState('');
    const [users, setUsers] = useState(['']);
    const [isEdit, setIsEdit] = useState(false);

    function handleSubmit(value: Role) {
        value.moduleIds = getSelectedModule();

        if (value.id.length === 0) {
            createRole(value).then(() => {
                history.push(`/roles`);
            });
        }
        else {
            updateRole(value).then(() => {
                history.push(`/roles`);
            });
        }
    }

    useEffect(() => {
        if (id) loadRole(id).then(roleFromDb => {
            if (roleFromDb) {
                let role = {
                    id: roleFromDb.id,
                    name: roleFromDb.name,
                    description: roleFromDb.description,
                    isDefault: roleFromDb.isDefault,
                    moduleIds: roleFromDb.moduleIds
                }

                let userNames = Array<string>();
                roleFromDb.users?.map(u => {
                    if (u.displayName)
                        userNames.push(u.displayName);
                });

                setUsers(userNames)

                setRole(role);
                loadApplications(true).then(() => {
                    roleFromDb.moduleIds.forEach(moduleId => {
                        setSelectedModule(moduleId, true);
                    });
                });

                setTitle('Edit Role');
                setIsEdit(true);
                if (role.isDefault) {
                    toast.warning('Default Roles are not editable.');
                }
            }

        })
        else {
            loadApplications(true);
            setTitle('Add Role');
            setIsEdit(false);
        }
    }, [loadApplications, id, loadRole, setRole]);


    if (loadingInitial) return <LoadingComponet content='Loading...' />

    return (
        <Formik
            enableReinitialize
            initialValues={role}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={Yup.object({
                name: Yup.string().required('Role field is required.'),
                description: Yup.string().required('Description field is required.')
            })}
        >
            {
                ({ handleSubmit, isSubmitting, errors }) => (
                    <StyledContainer>
                        <StyledHeader content={title} />
                        <Segment clearing>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <TextInput disabled={role.isDefault} placeholder='Role' name='name' label='Role' />
                                <TextArea disabled={role.isDefault} placeholder='Description' name='description' label='Description' rows={3} />
                                {isEdit ?
                                    <>
                                        <label style={{ fontSize: 13, fontWeight: 700 }}>Users</label>
                                        <Segment style={{ maxHeight: 100, overflowY: "auto" }}>
                                            {users.map(name => {
                                                return (
                                                    <Label style={{ marginBottom: 5 }} as='a'>{name}</Label>
                                                )
                                            })}

                                        </Segment>
                                    </> : ""
                                }
                                <Header style={{ marginTop: 15 }} size='medium' >Select Module</Header>
                                <Tab
                                    style={{ marginTop: 15 }}
                                    panes={selectApplication.map((application) => {
                                        return {
                                            menuItem: application.name,
                                            key: application.id,
                                            render: () =>
                                                <Tab.Pane key={application.id}>
                                                    <Table compact celled definition>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell as={() => {
                                                                    if (!role.isDefault) {
                                                                        return <div style={{
                                                                            backgroundColor: '#f7f7f7',
                                                                            borderLeft: '1px solid rgba(34,36,38,.1)',
                                                                            borderTop: '1px solid rgba(34,36,38,.1)',
                                                                            borderBottom: '1px solid rgba(34,36,38,.1)',
                                                                            padding: '.7em .7em'
                                                                        }}>
                                                                            <Checkbox
                                                                                toggle
                                                                                checked={application.isChecked}
                                                                                onClick={(event, data) => {
                                                                                    const { checked } = data;
                                                                                    if (checked)
                                                                                        setSelectedApplication(application.id, checked);
                                                                                    else
                                                                                        setSelectedApplication(application.id, false);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    else return <Table.HeaderCell></Table.HeaderCell>

                                                                }}
                                                                />
                                                                <Table.HeaderCell>Module</Table.HeaderCell>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {application.modules.map(module => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.Cell collapsing>
                                                                            <Checkbox
                                                                                disabled={role.isDefault}
                                                                                checked={module.isChecked}
                                                                                onClick={(event, data) => {
                                                                                    const { checked } = data;
                                                                                    if (checked)
                                                                                        setSelectedModule(module.id, checked);
                                                                                    else
                                                                                        setSelectedModule(module.id, false);
                                                                                }}
                                                                                id={module.id}
                                                                                toggle
                                                                            />
                                                                        </Table.Cell>
                                                                        <Table.Cell>{module.name}</Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </Tab.Pane>
                                        }
                                    })} />
                                {!role.isDefault ?
                                    <>
                                        <Button style={{ marginTop: 15 }} floated='right' content='Cancel' onClick={() => history.push('/roles')} size='large' />
                                        <Button style={{ marginTop: 15 }} loading={roleLoading} floated='right' content='Save' primary type='submit' size='large' />
                                    </> :
                                    <Button style={{ marginTop: 15 }} floated='right' content='Back' onClick={() => history.push('/roles')} size='large' />
                                }
                            </Form>
                        </Segment>
                    </StyledContainer>
                )
            }
        </Formik>

    );
};

export default observer(RoleForm);
